import Logo from '../assets/Logo.png'
import izumi from '../assets/izumi.png'
import manta from '../assets/manta.png'
import { connectWallet } from '../Adulam'
import { truncate, useGlobalState } from '../store'


const Header = () => {
  const [connectedAccount] = useGlobalState('connectedAccount')

  return (
    
    <nav >
      <div className="flex flex-row justify-between  items-center md:flex-[0.5] flex-initial">
       
      </div>
      <img src={manta} alt="logo" className="w-12" />
     <img src={Logo} alt="logo" className="w-12" />
     <img src={izumi} alt="logo" className="w-12" />
      <div class="buttons-container  ">  
</div>

      {connectedAccount ? (
        <button
          className="rounded-l bg-gradient px-5 py-3 text-base font-medium text-[#393d3f] transition duration-200 hover:shadow-xl hover:shadow-[#6025F5]/50 shadow-lg 
          ">
          {truncate(connectedAccount, 4, 4, 11)}
        </button>
      ) : (
        <button
          className="rounded-l bg-gradient px-5 py-3 text-base font-medium text-[#393d3f] transition duration-200 hover:shadow-xl hover:shadow-[#6025F5]/50 shadow-lg 
          shadow-[#e32970] "
          onClick={connectWallet}
        >
         app
        </button>

      
      )}
      </nav>
    
  )
}

export default Header
